<template>
    <b-overlay :show="isLoading" rounded="lg">
      <b-card title="Clear AMK Spare Part" sub-title="Clear out AMK Spare Part data from RSP System">
        <b-modal
          id="promptLoading"
          ref="modal"
          v-model="promptLoading"
          title="Processing..."
          hide-footer
        >
          <div class="progress" style="height: 30px; margin: 20px 0;">
            <div class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" :aria-valuenow="clearCounter * 100 / clearLength.length" aria-valuemin="0" aria-valuemax="100" :style="`width: ${clearCounter * 100 / clearLength.length}%`">
              <span class="progress-bar-title">{{ clearCounter }} / {{ clearLength.length }}</span>
            </div>
          </div>
        </b-modal>
        <b-modal v-model="modalClear" title="Clear AMK Spare Part Process">
        <b-container>
          <b-row>
            <b-col cols="12">
              <div class="d-flex">
                Are you sure want to clear {{clearLength.length}} DO AMK spare parts ?
              </div>
            </b-col>
          </b-row>
        </b-container>

        <template #modal-footer>
          <div class="w-100 d-flex justify-content-center align-items-center">
            <b-button variant="success" class="ml-1" @click="clearAmkSpareparts"
              >Confirm</b-button
            >
          </div>
        </template>
      </b-modal>
        <b-col cols="12">
          <!--this is options for search data, only "Filter by Status" will dissapear on mobile view-->
          <br>
          <b-row class="hide-on-mobile">
            <b-col md="6"> 
              <span
                >Please enter the desired delivery date to clear the AMK spare part</span
              ><br />
              <b-row>
                <b-col md="10" cols="9">
                  <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  v-model="delivDate"
                  style="margin-top:5px !important;"
                  :disabled-date="disableDates"
                  />
                </b-col>
                <b-col md="2" cols="3" style="margin-top:5px !important;">
                  <b-button variant="danger" @click="cleanQuery">X</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="hide-on-mobile">
            <b-col md="6">
              <h3 style="margin-top: 20px; margin-bottom: 20px;">
                or
              </h3>
            </b-col>
          </b-row>
          <b-row class="hide-on-mobile">
            <b-col md="6"> 
              <label for="search" class="form-label">Please enter the desired DO number to clear the AMK spare part</label>
              <b-form-input
                v-model="search"
                type="search"
                placeholder="Input DO Number"
                style="float: right;"
              />
            </b-col>
          </b-row>
          <b-row class="hide-on-desktop">
            <b-col md="6"> 
              <span
                >Please enter the desired delivery date to clear the AMK spare part</span
              ><br />
              <b-row>
                <b-col md="10" cols="9">
                  <b-form-datepicker
                  @input="(value) => fireFiltering(value)"
                  v-model="delivDate"
                  style="margin-top:5px !important;"
                  :disabled-date="disableDates"
                  />
                </b-col>
                <b-col md="2" cols="3" style="margin-top:5px !important;">
                  <b-button variant="danger" @click="cleanQuery">X</b-button>
                </b-col>
              </b-row>
            </b-col>
          </b-row>
          <b-row class="hide-on-desktop">
            <b-col md="6">
              <h3 style="margin-top: 20px; margin-bottom: 20px;">
                or
              </h3>
            </b-col>
          </b-row>
          <br>
          <b-row class="hide-on-desktop">
            <b-col cols="6"> 
              <label for="search" class="form-label">Please input DO number that want Amk Spare Part Clear</label>
              <b-form-input
                v-model="search"
                type="search"
                placeholder="DO"
                style="float: right;"
              />
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-2">
            <b-col cols="12" md="3" sm="3">
              <b-button style="margin-top: 22px;" @click="doFiltering" variant="primary">Search</b-button>
            </b-col>
            <b-col cols="12" md="3" sm="3">
              <b-button style="margin-top: 22px;" @click="modalClear = true" variant="success">Clear Amk Spare Part</b-button>
            </b-col>
          </b-row>
          <b-row>
            <b-table
              responsive
              small
              hover
              bordered
              show-empty
              :items="dataTable"
              :fields="fields"
              :busy.sync="tableLoading"
              :stacked="stackedStatus"
            >
              <template #cell(index)="row">
                {{ row.index + 1 + (currentPage - 1) * 10 }}
              </template>

              <template v-slot:cell(deliveryDate)="row">
                {{ dates(row.item.deliveryDate) }}
              </template>
              <template #cell(progress)="row">
                <b-progress :value="progressValue(row.item.status)" :max="100" animated></b-progress>
              </template>
            </b-table>
          </b-row>
          <b-row style="margin-top:10px">
            <b-col md="9" />
            <b-col md="3">
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                @change="onPageChange"
                v-model="currentPage"
                align="fill"
                class="hide-on-desktop"
              />
              <b-pagination
                :total-rows="totalRows"
                :per-page="perPage"
                @change="onPageChange"
                v-model="currentPage"
                class="hide-on-mobile"
                style="float: right"
              />
            </b-col>
          </b-row>
        </b-col>
      </b-card>
    </b-overlay>
  </template>
  
  <script>
  import { mapActions } from "vuex";
  import axios from "@/axios";
  import { userAccess, dateFormat, sleep } from "@/utils/utils";
  import DatePicker from "vue2-datepicker";
  import "vue2-datepicker/index.css";
  
  export default {
    components: {
      "date-picker": DatePicker,
    },
    data() {
      return {
        search : "",
        isLoading : false,
        canEdit : false,
        selectStatus : "",
        status : [],
        buttonSubmit : false,
        tableLoading: false,
        delivDate: "",
        doData : {},
        fields: [
          { key: "index", label: "No" },
          { key: "sapSo", label: "SAP SO", filterable: true, sortable: true },
          { key: "sapDo", label: "SAP DO", filterable: true, sortable: true },
          {
            key: "deliveryDate",
            label: "Delivery Date",
            filterable: true,
            sortable: true,
          },
          { key: "status", label: "STATUS", filterable: true, sortable: true },
          // { key: "progress", label: "Progress", filterable: true, sortable: true },
        ],
        stackedStatus: "md",
        perPage: 10,
        currentPage: 1,
        totalRows: 1,
        dataTable : [],
        clearCounter : 0,
        clearLength : [],
        promptLoading : false,
        modalClear : false,
      }
    },
    computed: {
      permission() {
        let result = userAccess("Clear Amk Spare Part", "utilitytools_menu");
        // console.log("Delivery Orders Sumitomo",result)
        return result;
      },
      filterDelivery() {
        var result = this.deliveryOrders;
        console.log('result', result);
        return result;
      },
    },
    created() {
      document.title = "Clear Amk Spare Part | RSP";
    },
    mounted() {
      this.tableLoading = true
      this.queryDelivery()
    },
    watch: {
    },
    methods: {
      ...mapActions({
        getListAmkSparePart: "delivery/getListAmkSparePart",
        clearAmkSparePart: "delivery/clearAmkSparePart"
      }),
      queryDelivery() {
        let newDate = ""
        if(this.delivDate != ""){
          newDate = new Date(this.delivDate).toLocaleDateString()
        }
        this.getListAmkSparePart({
          page: this.currentPage,
          entry: this.perPage,
          sapDo: this.search,
          delivDate: newDate,
        }).then((data) => {
          this.processMetadata(data.metadata)
          this.tableLoading = false
          this.dataTable = data.data
          this.clearLength = data.metadata.allData
        }).catch((e) => {
          this.tableLoading = false
          console.log(e);
          this.$bvToast.toast(e.response.message, {
          title: "Warning",
          variant: "danger",
          solid: true,
          });
          console.log(e);
        });
      },
      doFiltering() {
        //this.search = value;
        this.tableLoading = true

        this.currentPage = 1;
        this.queryDelivery();
      },
      processMetadata(metadata) {
        let { totalData } = metadata;
        this.totalRows = totalData;
        this.tableLoading = false;
      },
      async clearAmkSpareparts() {
          this.modalClear = false
          this.promptLoading = true
          this.isLoading = true;
          let newDate = "";
          if (this.delivDate != "" && this.delivDate != null && this.delivDate != undefined) {
              newDate = new Date(this.delivDate).toLocaleDateString();
          }
          this.clearCounter = 0

          const clearNext = async (index) => {
            console.log("this.clearCounter : ", this.clearCounter, ", this.clearLength : ", this.clearLength.length);
            if (index >= this.clearLength.length) {
              // Jika sudah mencapai akhir data, hentikan loop
              this.isLoading = false;
              this.promptLoading = false
              this.$bvToast.toast(`${this.clearCounter} / ${this.clearLength.length} DO success to Clear AMK Spare Part`, {
                  title: "Success",
                  variant: "success",
                  solid: true,
              });
              await sleep(2000);
              return;
            }
            try {
              const response = await this.clearAmkSparePart({ deliveryOrder: this.clearLength[index] });
              await sleep(1000);
              this.clearCounter++
              await clearNext(index + 1); // Lanjutkan ke iterasi berikutnya
            } catch (error) {
              console.log(error);
              const errorMessage = error.response.data.message;
              this.$bvToast.toast(errorMessage + `, ${this.clearCounter} / ${this.clearLength.length} success`, {
                  title: "Fail",
                  variant: "danger",
                  solid: true,
                  titleTag: 'strong' // Ini akan membuat judul tebal
              });
              await sleep(2000);
              // Hentikan loop jika terjadi kesalahan
              this.isLoading = false;
              this.promptLoading = false;
              return;
            }
          };

          await clearNext(0); // Mulai iterasi dari indeks 0
      },
      fireFiltering(date){
        this.delivDate = date;
        console.log("this.delivDate", this.delivDate);
      },
      disableDates(time) {
        // Disable dates before 6 Nov 2023
        const disabledDate = new Date('2023-11-06');
        return time.getTime() < disabledDate.getTime();
      },
      dates(date) {
        return dateFormat(date);
      },
      progressValue(status) {
        if (status === 'New') {
          return 0;
        } else if (status === 'Edit Delivery ' || status === 'Edit in Progress' || status === 'Cancel Delivery' || status === 'Delivery Canceled' || status === 'Scanning in Progress' || status === 'Scanning Completed') {
          return 33;
        } else if (status === 'Delivery in Progress') {
          return 66;
        } else if (status === 'Fulfilled' || status === 'Collected' || status === 'Returned' || status === 'Waiting Acknowledgment' || status === 'Waiting approval' || status === 'Approved' || status === 'Return Completed') {
          return 100;
        } else {
          return 0;
        }
      },
      onPageChange(page) {
        this.tableLoading = true
        this.currentPage = page;
        this.queryDelivery();
      },
      cleanQuery() {
        this.delivDate = "";
        this.currentPage = 1;
        this.queryDelivery();
      },
    },
  };
  </script>
  <style scope>
  .hide-at-all {
    display: none !important;
  }
  
  /* For mobile phones: */
  @media (max-width: 761px) {
    .hide-on-mobile {
      display: none !important;
    }
  }
  
  /* For computer: */
  @media (min-width: 761px) {
    .hide-on-desktop {
      display: none !important;
    }
    .show-on-desktop {
      display: inline !important;
    }
  }
  </style>
  